/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const typeUser = currentUser && currentUser?.attributes?.profile?.publicData?.typeuser;

  let approveExperience = user?.attributes?.profile?.publicData?.['approve-experience'];
  let approveService = user?.attributes?.profile?.publicData?.['approve-for-services'];

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const communityLink =
    isAuthenticated && currentUser && typeUser && typeUser === 'coach' ? (
      <div
        className={css.navigationLink}
        onClick={() => window.open('https://t.me/+QE7DDWbiC_JkZWY5')}
      >
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.community" />
        </span>
      </div>
    ) : null;

  const findYourCoachLink = isAuthenticated ? (
    <NamedLink className={css.navigationLink} name="SearchPage">
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.searchAppointments" />
      </span>
    </NamedLink>
  ) : null;

  /*
     className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))
    */

  const learningLink =
    isAuthenticated && currentUser && typeUser && typeUser === 'coach' ? (
      <NamedLink className={css.navigationLink} name="LearningPage">
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.myLearning" />
        </span>
      </NamedLink>
    ) : null;

  const resourcesLink =
    isAuthenticated && currentUser && typeUser && typeUser === 'coach' ? (
      <NamedLink
        className={css.navigationLink}
        name="CMSPage"
        params={{ pageId: 'resources-page' }}
      >
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.workResources" />
        </span>
      </NamedLink>
    ) : null;

  {
    /* Cambiar color de texto*/
  }
  const validateExperience =
    isAuthenticated && currentUser && typeUser && typeUser === 'coach' ? (
      <NamedLink className={css.navigationLink} name="ValidateExperiencePage">
        <span className={css.secondMenu}>
          <FormattedMessage id="UserNav.validateExperience" />
        </span>
      </NamedLink>
    ) : null;

  const profileLink = isAuthenticated ? (
    <NamedLink className={css.navigationLink} name="ProfileSettingsPage">
      <span className={css.secondMenu}>
        <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
      </span>
    </NamedLink>
  ) : null;

  const accountSettingsLink = isAuthenticated ? (
    <NamedLink className={css.navigationLink} name="AccountSettingsPage">
      <span className={css.secondMenu}>
        <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
      </span>
    </NamedLink>
  ) : null;

  const servicesLink =
    isAuthenticated && approveExperience && approveService ? (
      <NamedLink className={css.navigationLink} name="ManageListingsPage">
        <span className={css.secondMenu}>
          <FormattedMessage id="UserNav.services" />
        </span>
      </NamedLink>
    ) : null;

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        {learningLink}
        {resourcesLink}
        {communityLink}
        {findYourCoachLink}
        {/* Cambiar a una constante */}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>

        {/* {validateExperience} */}

        <div className={css.spacer} />
        <hr style={{ border: '1px solid #C6C7C8', width: '100%', marginBottom: '8px' }} />
        {profileLink}
        {validateExperience}
        {accountSettingsLink}
        {servicesLink}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
