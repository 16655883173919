import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Button,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const typeUser = currentUser && currentUser?.attributes?.profile?.publicData?.typeuser;
  const validation =
    currentUser && currentUser?.attributes?.profile?.publicData?.['approve-experience'];

  const approveService =
    currentUser && currentUser?.attributes?.profile?.publicData?.['approve-for-services'];

  const isClient =
    currentUser && currentUser?.attributes?.profile?.publicData?.typeuser === 'cliente';

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu =
    authenticatedOnClientSide && validation && approveService && !isClient ? (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="ValidateExperiencePage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ValidateExperiencePage')
              )}
              name="ValidateExperiencePage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.validateExperience" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ManageListingsPage')
              )}
              name="ManageListingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="UserNav.services" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : authenticatedOnClientSide && !isClient ? (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="ValidateExperiencePage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ValidateExperiencePage')
              )}
              name="ValidateExperiencePage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.validateExperience" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : authenticatedOnClientSide && isClient ? (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : null;

  /*const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );*/

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <div className={css.signupContainer}>
      {/*<button className={css.signupButton}>Crear cuenta</button>*/}
      <NamedLink name="SignupPage" className={css.signupButton}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </NamedLink>
    </div>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const searchLink = (
    <NamedLink className={css.inboxLink} name="SearchPage">
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.searchAppointments" />
      </span>
    </NamedLink>
  );

  const communityLink =
    isAuthenticated && currentUser && typeUser && typeUser === 'coach' ? (
      <div className={css.inboxLink} onClick={() => window.open('https://t.me/+QE7DDWbiC_JkZWY5')}>
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.community" />
        </span>
      </div>
    ) : null;

  const learningLink =
    isAuthenticated && currentUser && typeUser && typeUser === 'coach' ? (
      <NamedLink className={css.inboxLink} name="LearningPage">
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.myLearning" />
        </span>
      </NamedLink>
    ) : null;

  const resourcesLink =
    isAuthenticated && currentUser && typeUser && typeUser === 'coach' ? (
      <NamedLink className={css.inboxLink} name="CMSPage" params={{ pageId: 'resources-page' }}>
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.workResources" />
        </span>
      </NamedLink>
    ) : null;

  const inboxLink = isAuthenticated ? (
    <NamedLink className={css.inboxLink} name="InboxPage" params={{ tab: 'orders' }}>
      {/*params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}*/}
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  return (
    <nav className={classes}>
      <LinkedLogo
        className={css.logoLink}
        layout="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
      />

      {search}

      {learningLink}
      {resourcesLink}
      {communityLink}
      {searchLink}
      {inboxLink}
      {profileMenu}
      {loginLink}
      {signupLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
